<!-- 28-5-2, 28-6-2, 28-7-2, 28-8-2 -->
<template>
  <div v-if="rating">
    <rating-detail-item
      :rating="rating"
      :isTextAreaShown="true"
      :disabled="(rating?.rating as RequestRating)?.status === RatingStatus.EXPLAINING || (rating?.releasedRatings as ReleasedRatings[])[0].status === RatingStatus.EXPLAINING"
      :changeMessage="
        $t(
          'studio.prj_prod_mngmt.rating_build_review.self_review.see_history.case10_rating_rejected_title'
        )
      "
      :title="$t('studio.prj_prod_mngmt.rating_build_review.self_review.rating_reject_title')"
    >
      <right-wing>
        <right-wing-item
          :rightWingTitle="
            $t('studio.prj_prod_mngmt.rating_build_review.self_review.rating_reject_title')
          "
        >
          <safe-html
            :html="`${$t(
              'studio.prj_prod_mngmt.rating_build_review.self_review.rating_reject_guide1_1'
            )} <br /> ${$t(
              'studio.prj_prod_mngmt.rating_build_review.self_review.rating_reject_guide1_2'
            )} <br /> ${$t(
              'studio.prj_prod_mngmt.rating_build_review.self_review.rating_reject_guide1_3'
            )}`"
          />
        </right-wing-item>
      </right-wing>
    </rating-detail-item>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import { useRoute } from 'vue-router';

import { definePageMeta } from '@/.nuxt/imports';
import { fetchRatingsProductsApi } from '@/apis/rating.api';
import RightWing from '@/components/app/right-wing/index.vue';
import RightWingItem from '@/components/app/right-wing/item.vue';
import SafeHtml from '@/components/common/safe-html.vue';
import RatingDetailItem from '@/components/rating/detail/rating-detail-item.vue';
import { RatingStatus } from '@/enums/rating.enum';
import type {
  RatingProductResponse,
  ReleasedRatings,
  RequestRating
} from '@/types/rating/rating.response.type';
import { getCorrectRating } from '@/utils/rating.util';

definePageMeta({
  pageTitle: 'studio.prj_prod_mngmt.rating_build_review.self_review.breadcrumb4_6',
  middleware: ['check-product-writable-middleware', 'handle-product-data-middleware']
});

const route = useRoute();

const productNo = route.params.productId as string;

const rating = ref<RatingProductResponse>();

const init = async () => {
  const res = await fetchRatingsProductsApi(productNo);
  if (res) {
    rating.value = res;

    const ratingData = getCorrectRating(res.requestRating, res.releasedRatings);

    if (ratingData) {
      rating.value.rating = ratingData;
    }
  }
};

init();
</script>
